<template>
  <v-form ref="form" class="text-center">
    <v-window v-model="onboarding">
      <v-window-item :value="1">
        <v-card
          :disabled="loading"
          tile
          flat
          color="transparent"
          class="mb-2"
          :width="width"
        >
          <FeelingRatingPicker
            :selected="rating"
            @update="rating = $event"
            class="mt-5"
          />

          <v-scroll-y-transition>
            <v-chip-group
              v-if="evaluated && rating < 5"
              v-model="sentencesSelected"
              column
              multiple
              active-class="feedback-sentence"
              class="mt-3"
            >
              <v-chip v-for="(text, i) in sentences" :key="i" :value="text">
                {{ text }}
              </v-chip>
            </v-chip-group>
          </v-scroll-y-transition>

          <v-textarea
            v-if="showCommentSession"
            :disabled="disableFeedback"
            filled
            class="mt-5"
            :label="$t('feedbackRating.leaveAComment')"
            value="message"
            v-model="message"
            :height="txtAreaHeight"
            :placeholder="
              sentencesSelected?.length > 0
                ? `${$t('feedbackRating.why')}...`
                : ''
            "
            :persistent-placeholder="sentencesSelected?.length > 0"
          ></v-textarea>

          <v-btn
            v-if="showCommentSession"
            color="primary"
            class="text-none text-body-1 font-weight-medium"
            :loading="loading"
            :disabled="disableFeedback"
            @click="registerUserFeedback"
          >
            {{ $t("feedbackRating.sendSugestion") }}
          </v-btn>
        </v-card>
      </v-window-item>

      <v-window-item :value="2" class="mb-4">
        <v-card
          min-height="250"
          elevation="0"
          class="mx-auto my-4 d-flex flex-column align-center"
        >
          <v-img :src="image" :width="imgWidth"></v-img>

          <v-card-title>
            {{ $t("feedbackRating.evaluationRegistered") }}
          </v-card-title>

          <v-btn
            v-if="!showReviewMarketplace"
            @click="resetValues"
            color="primary"
            large
            text
            class="text-none text-body-1 font-weight-medium"
          >
            <v-icon v-text="'mdi-plus-circle'" left />
            {{ $t("feedbackRating.newAssessment") }}
          </v-btn>
          <v-btn
            v-else
            @click="redirectToMarketplace"
            color="primary"
            large
            text
            class="text-none text-body-1 font-weight-medium"
          >
            <v-icon v-text="'mdi-store'" left />
            {{ $t("feedbackRating.rateOnMarketplace") }}
          </v-btn>

          <v-btn
            v-if="showReviewMarketplace"
            @click="resetValues"
            class="text-none text-body-2 font-weight-medium mt-2"
            text
            color="secondary lighten-2"
          >
            {{ $t("feedbackRating.newAssessment") }}
          </v-btn>

          <v-btn
            v-else-if="showDismiss"
            @click="close"
            class="text-none text-body-2 font-weight-medium mt-2"
            text
            color="secondary lighten-2"
          >
            {{ $t("action.close") }}
          </v-btn>
        </v-card>
      </v-window-item>
    </v-window>
  </v-form>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import FeelingRatingPicker from "@/components/forms/field_pickers/FeelingRatingPicker.vue";
import { openInNewTab } from "@/helpers/services/utils";

export default {
  name: "FeedbackRating",

  props: {
    action: { type: String, required: true },
    hideCommentByDefault: { type: Boolean, default: false },
    imgWidth: { type: String, default: "170" },
    ratingClass: { type: String, default: "" },
    selected: { type: [Number, String], default: 0 },
    showDismiss: { type: Boolean, default: false },
    width: { type: [Number, String], default: "" },
    txtAreaHeight: { type: [Number, String], default: "" },
  },

  components: { FeelingRatingPicker },

  data: () => ({
    rating: 0,
    message: "",
    onboarding: 1,
    image: require("@/assets/images/feedback_check.png"),
    loading: false,
    currentUserData: {},
    sentencesSelected: [],
  }),

  computed: {
    ...mapGetters(["token", "currentUser"]),

    disableFeedback() {
      return this.rating == 0;
    },

    evaluated() {
      return this.rating > 0;
    },

    showCommentSession() {
      if (this.hideCommentByDefault) {
        return this.evaluated;
      }
      return true;
    },

    showReviewMarketplace() {
      return this.rating >= 4;
    },

    sentencesByAction() {
      return {
        edit_company_tags: [
          this.$t("feedbackRating.didNotWork"),
          this.$t("feedbackRating.confusingInterface"),
          this.$t("feedbackRating.slowPerformance"),
          this.$t("feedbackRating.incorrectInformation"),
        ],
        apply_signature_in_batch: [
          this.$t("feedbackRating.didNotWork"),
          this.$t("feedbackRating.confusingInterface"),
          this.$t("feedbackRating.slowPerformance"),
          this.$t("feedbackRating.incorrectSignature"),
          this.$t("feedbackRating.tooComplexModel"),
        ],
        onboarding_user: [
          this.$t("feedbackRating.didNotWork"),
          this.$t("feedbackRating.moreOptionsNeeded"),
          this.$t("feedbackRating.confusingInterface"),
          this.$t("feedbackRating.slowPerformance"),
          this.$t("feedbackRating.incorrectChanges"),
        ],
        delegate_email: [
          this.$t("feedbackRating.didNotWork"),
          this.$t("feedbackRating.confusingInterface"),
          this.$t("feedbackRating.slowPerformance"),
          this.$t("feedbackRating.incorrectDelegation"),
          this.$t("feedbackRating.emailNotFound"),
        ],
        send_as_email: [
          this.$t("feedbackRating.didNotWork"),
          this.$t("feedbackRating.confusingInterface"),
          this.$t("feedbackRating.slowPerformance"),
          this.$t("feedbackRating.incorrectEmail"),
          this.$t("feedbackRating.emailNotFound"),
        ],
      };
    },

    sentences() {
      return this.sentencesByAction[this.action] || [];
    },
  },

  watch: {
    rating() {
      this.sentencesSelected = [];
      this.$emit("update", this.rating);
    },

    selected() {
      if (this.selected) {
        this.rating = this.selected;
      }
    },
  },

  methods: {
    ...mapMutations(["setSnackBar", "setCurrentUser"]),

    close() {
      this.setCurrentUser(this.currentUserData);
      this.$emit("close");
    },

    resetValues() {
      this.message = "";
      this.rating = 0;
      this.onboarding = 1;
    },

    closeFeedback() {
      this.resetValues();
      this.close();
    },

    async registerUserFeedback() {
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/feedback`;

      this.loading = true;

      const payload = {
        action: this.action,
        feedback_rating: this.rating,
        feedback_note: this.message || "user_no_comment",
        feedback_sentences: this.sentencesSelected,
      };

      await this.$axios
        .post(url, payload, {
          headers: { Authorization: "Bearer " + this.token },
        })
        .then(({ data }) => {
          this.onboarding = 2;
          this.currentUserData = data;
        })
        .catch((error) => {
          this.setSnackBar({
            message: "Erro ao registrar avaliação, por favor, tente novamente",
            color: "error",
            show: true,
          });
          this.$emit("evaluated", false);
          console.error("registerUserFeedback", error);
          this.$emit("update", 0);
          this.close();
        });

      this.loading = false;
    },

    redirectToMarketplace() {
      const link = `https://workspace.google.com/marketplace/app/conecta_suite/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      openInNewTab(link);
    },
  },

  beforeMount() {
    if (this.selected) {
      this.rating = this.selected;
    }

    this.currentUserData = this.currentUser;
  },
};
</script>

<style>
.v-chip-group .feedback-sentence {
  background-color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
  color: var(--v-background-base) !important;
}
</style>
