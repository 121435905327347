<template>
  <SimpleDialog
    :id="id"
    :title="title"
    :show="show"
    width="600"
    :disabled-action="disabledAction"
    :action-text="actionText"
    button-class="text-none text-subtitle-1 font-weight-medium elevation-0"
    color="accent"
    :description="description"
    :showLoading="loading"
    @close="$emit('closeDialog')"
    @action="editDate()"
    :persistent="true"
  >
    <template v-slot:body>
      <v-card-text class="px-0">
        <v-menu
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              data-testid="trial-date-input"
              v-model="formatedDate"
              :label="labelInput"
              :disabled="disableField"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDate"
            @input="datePicker = false"
          ></v-date-picker>
        </v-menu>
      </v-card-text>
    </template>
  </SimpleDialog>
</template>

<script>
import SimpleDialog from "@/components/base/SimpleDialog";
import * as moment from "moment";
import { mapActions } from "vuex";
export default {
  name: "EditPlanDate",
  components: { SimpleDialog },
  props: {
    id: { type: String, default: "" },
    planKey: { type: String, default: "" },
    show: { type: Boolean, required: true },
    disabledAction: { type: Boolean, default: false },
    title: { type: String, default: "" },
    actionText: { type: String, default: "" },
    labelInput: { type: String, default: "" },
    description: { type: String, default: "" },
    disableField: { type: Boolean, default: true },
    date: { type: String },
  },
  data() {
    return {
      newDate: moment().format("YYYY-MM-DD"),
      datePicker: false,
      loading: false,
    };
  },
  computed: {
    formatedDate() {
      return moment(this.newDate).format("DD/MM/YYYY");
    },
  },
  methods: {
    ...mapActions(["updateHiredPlan", "getContractedPlans"]),
    editDate() {
      this.loading = true;
      let payload = {};
      switch (this.id) {
        case "renewal_date":
          payload.renewal_date = this.newDate;
          break;
        case "end_trial":
          payload.end_trial = this.newDate;
          break;
      }
      this.updateHiredPlan({
        payload: payload,
        plan_key: this.planKey,
      })
        .then(({ data }) => {
          this.$emit("updatePlan", data);
        })
        .catch((err) => {
          console.log("updateHiredPlan()", err);
          const snackProps = {
            snackbarText: "Erro ao editar a data deste Plano",
            snackbarType: "error",
            showSnackbar: true,
          };
          this.$emit("showSnackbarToast", snackProps);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
template
