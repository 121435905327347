var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"fullscreen":"","hide-overlay":"","height":"100%","scrollable":"","transition":"dialog-bottom-transition","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-app-bar',{staticStyle:{"z-index":"999"},attrs:{"absolute":!_vm.$vuetify.breakpoint.mobile,"app":_vm.$vuetify.breakpoint.mobile,"height":"80","color":"primary","dark":"","flat":""}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-close')}})],1),_c('v-app-bar-title',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.beta)?_c('v-chip',{staticClass:"ms-2 font-weight-medium",attrs:{"color":"success","dark":"","title":_vm.$t('common.availableIndefinitely'),"x-small":""}},[_vm._v("Beta ")]):_vm._e()],1),_c('v-stepper',{key:_vm.mobile,staticClass:"px-0 mx-0 mt-12 pt-8 overflow-y-auto",attrs:{"flat":"","tile":"","vertical":_vm.mobile},model:{value:(_vm._currentStep),callback:function ($$v) {_vm._currentStep=$$v},expression:"_currentStep"}},[_c('div',[(_vm.mobile)?_c('div',{staticClass:"mt-8 background fill-height"},_vm._l((_vm.steps),function(step,i){return _c('div',{key:step.id},[(_vm.showSteps)?_c('v-stepper-step',{class:`${
              _vm.currentStep === step.id ? 'background rounded-t-lg' : ''
            } ${step.disabled ? 'disabled' : ''} py-4`,attrs:{"step":step.id},on:{"click":function($event){step.disabled || _vm.linear ? () => {} : (_vm._currentStep = step.id)}}},[_c('div',{staticClass:"primary--text text-none pl-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t(step.titleKey))+" ")]),_c('div',{staticClass:"primary--text pl-2 caption font-weight-regular"},[_vm._v(" "+_vm._s(_vm.$t(step.subtitleKey))+" ")]),(step.disabled)?_c('v-chip',{staticClass:"ml-2 mt-2",attrs:{"color":"success","x-small":""}},[_vm._v(_vm._s(_vm.$t("common.soon"))+" ")]):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"pl-2 caption mt-1",staticStyle:{"line-height":"14px"}},[_vm._v(" "+_vm._s(_vm.$t(step.subtitleKey))+" ")]):_vm._e()],1):_vm._e(),_c('v-stepper-content',{staticClass:"px-0 mx-0",attrs:{"step":step.id}},[_c('v-card',{staticClass:"overflow-y-auto px-2",attrs:{"flat":"","tile":""}},[(_vm.currentStep == step.id)?_c('div',[_c('FullscreenStep',{attrs:{"showDivider":_vm.showDivider},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._t(`stepper-content.${step.id}`)]},proxy:true},(step.id != 1)?{key:"actions",fn:function(){return [_vm._t(`stepper-content.actions`)]},proxy:true}:null],null,true)})],1):_vm._e()])],1),(i < _vm.steps.length - 1 && _vm.showDivider)?_c('v-divider',{staticClass:"mb-3"}):_vm._e()],1)}),0):_vm._e(),(!_vm.mobile)?_c('div',[_c('v-row',{class:`${
            _vm.$vuetify.breakpoint.smAndDown ? 'mt-5' : 'primary'
          } ma-0 pa-0`,attrs:{"no-gutters":""}},_vm._l((_vm.steps),function(step){return _c('v-col',{key:step.id},[(_vm.showSteps)?_c('v-stepper-step',{class:`${
                _vm.currentStep === step.id ? 'background rounded-t-lg' : ''
              }  ${step.disabled ? 'disabled' : 'link'} py-4`,staticStyle:{"height":"100%"},attrs:{"complete":_vm.currentStep > step.id,"step":step.id},on:{"click":function($event){step.disabled || _vm.linear ? null : (_vm._currentStep = step.id)}}},[_c('div',{class:`${
                  _vm.currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 font-weight-medium`},[_vm._v(" "+_vm._s(_vm.$t(step.titleKey))+" ")]),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{class:`${
                  _vm.currentStep === step.id ? 'primary--text' : 'white--text'
                } pl-2 caption mt-1`,staticStyle:{"line-height":"14px"}},[_vm._v(" "+_vm._s(_vm.$t(step.subtitleKey))+" ")]):_vm._e(),(step.disabled)?_c('v-chip',{staticClass:"ml-2 mt-2",attrs:{"color":"success","x-small":""}},[_vm._v(_vm._s(_vm.$t("common.soon"))+" ")]):_vm._e()],1):_vm._e()],1)}),1),_c('v-stepper-items',_vm._l((_vm.steps),function(step){return _c('v-stepper-content',{key:step.id,attrs:{"step":step.id}},[(_vm.currentStep == step.id)?_c('div',[_c('FullscreenStep',{attrs:{"showDivider":_vm.showDivider},scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._t(`stepper-content.${step.id}`)]},proxy:true},(step.id != 1)?{key:"actions",fn:function(){return [_vm._t(`stepper-content.actions`)]},proxy:true}:null],null,true)})],1):_vm._e()])}),1)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }