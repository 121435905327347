<template>
  <v-container style="max-width: 1160px">
    <div>
      <slot name="body"></slot>
    </div>
    <v-divider v-if="showDivider" />
    <div class="mt-5 d-flex justify-space-between">
      <slot name="actions"></slot>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "FullscreenStep",

  props: {
    showDivider: { type: Boolean, default: () => true },
  },
};
</script>
