<template>
  <div>
    <SubscriptionTypeTabs />

    <v-data-iterator :items="availablePlans" hide-default-footer>
      <template v-slot:header>
        <v-row
          v-if="$vuetify.breakpoint.mdAndDown"
          justify="center"
          class="ma-0 pa-0 mt-5 mb-8"
        >
          <LicensesDialogSelector
            :subscription-type="subscriptionType"
            @update="updatedMaxLicenses = $event"
          />
        </v-row>
      </template>

      <template v-slot:default="props">
        <div v-if="$vuetify.breakpoint.lgAndUp">
          <PlanFeaturesExpasion
            show-plans
            :licenses="updatedMaxLicenses"
            :subscription-type="subscriptionType"
            @select-plan="$emit('select-plan', $event)"
          />
        </div>

        <div v-else>
          <!-- VERSÃO TABLET OU MOBILE -->
          <v-row class="ma-0 pa-0">
            <v-col v-if="!isCellPhoneScreen">
              <UpgradePlanCard
                :subscription-type="subscriptionType"
                :licences="updatedMaxLicenses"
                :plan="featuredPlan"
                :client-has-this-plan="
                  checkIfClientHasThisPlan(featuredPlan.key)
                "
                @select-plan="$emit('select-plan', $event)"
              />

              <v-divider></v-divider>

              <PlanDescription maxWidth="400" :plan="featuredPlan" />
            </v-col>
            <v-col>
              <!-- VERSÃO MOBILE -->
              <v-carousel
                v-model="caru"
                hide-delimiters
                continuous
                cycle
                interval="5000"
                height="300"
                class="pa-0"
              >
                <v-carousel-item
                  v-for="(activePlan, index) in mobilePlansList"
                  :key="!isCellPhoneScreen ? index + 1 : index"
                  class="pa-0"
                >
                  <UpgradePlanCard
                    :class="`${
                      $vuetify.breakpoint.lgAndUp &&
                      index < props.items.lenght - 1
                        ? 'border-right'
                        : ''
                    }`"
                    :licences="updatedMaxLicenses"
                    :subscription-type="subscriptionType"
                    :plan="activePlan"
                    :client-has-this-plan="
                      checkIfClientHasThisPlan(activePlan.key)
                    "
                    @select-plan="$emit('select-plan', $event)"
                  />
                </v-carousel-item>
              </v-carousel>

              <v-divider></v-divider>

              <PlanDescription
                :clientHasThisPlan="
                  checkIfClientHasThisPlan(mobilePlansList[caru].key)
                "
                maxWidth="400"
                :plan="mobilePlansList[caru]"
              />
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-iterator>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { STATUS } from "@/helpers/variables/backendConstants.js";
import { FEATURED_SKU } from "@/helpers/variables/modules";

import UpgradePlanCard from "@/components/billing/UpgradePlanCard";
import PlanFeaturesExpasion from "@/components/billing/PlanFeaturesExpasion.vue";
import LicensesDialogSelector from "@/components/purchase/LicensesDialogSelector.vue";
import SubscriptionTypeTabs from "@/components/purchase/selfcheckout/SubscriptionTypeTabs.vue";
import PlanDescription from "@/components/billing/PlanDescription";

export default {
  name: "PurchasePlansSelector",

  components: {
    UpgradePlanCard,
    PlanFeaturesExpasion,
    LicensesDialogSelector,
    SubscriptionTypeTabs,
    PlanDescription,
  },

  data() {
    return {
      STATUS,
      FEATURED_SKU,
      caru: 0,
    };
  },

  computed: {
    ...mapGetters([
      "plans",
      "clientActivePlans",
      "usersNumber",
      "contractedPlans",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
    ]),

    updatedMaxLicenses: {
      get() {
        return this.selfCheckoutMaxLicenses;
      },
      set(newVal) {
        if (newVal >= this.usersNumber) {
          this.setSelfCheckoutMaxLicenses(newVal);
        }
      },
    },

    subscriptionType() {
      return this.selfCheckoutSubscriptionType;
    },

    featuredPlan() {
      return this.availablePlans.find((plan) => plan.sku === FEATURED_SKU);
    },

    nonFeaturedPlans() {
      return this.availablePlans.filter((plan) => plan.sku !== FEATURED_SKU);
    },

    isCellPhoneScreen() {
      return this.$vuetify.breakpoint.xs;
    },

    availablePlans() {
      return this.plans
        .filter((plan) => plan.is_active && plan.is_visible)
        .sort(function (a, b) {
          if (a.short_code === "conecta_suite") {
            return -1;
          }
          if (b.short_code === "conecta_suite") {
            return 1;
          }
          if (a.price < b.price) {
            return -1;
          }
          if (a.price > b.price) {
            return 1;
          }
          return 0;
        });
    },

    mobilePlansList() {
      if (this.isCellPhoneScreen) {
        return this.availablePlans;
      }
      return this.nonFeaturedPlans;
    },
  },

  methods: {
    ...mapMutations(["setSelfCheckoutMaxLicenses"]),

    checkIfClientHasThisPlan(key) {
      return this.contractedPlans.find(
        (plan) =>
          plan.plan.key === key &&
          [STATUS.TRIAL, STATUS.ACTIVE].includes(plan.valid_status) &&
          this.selfCheckoutSubscriptionType == plan.subscription_type
      )
        ? true
        : false;
    },
  },
};
</script>
