import {
  BASIC_SKU,
  STANDARD_SKU,
  SECURITY_SKU,
  ENTERPRISE_SKU,
} from "@/helpers/variables/backendConstants";

export const products = {
  conecta_sign: {
    title: "Conecta Sign",
    key: "conecta_sign",
    description:
      "Gerencie assinaturas de e-mail de forma simples e rápida. Crie modelos personalizados ou utilize nossos modelos prontos e edite em massa para todos os usuários, mantendo a identidade da sua empresa.",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-sign.png"),
  },
  conecta_track: {
    title: "Conecta Track",
    key: "conecta_track",
    description:
      "Saiba automaticamente onde estão alocadas as horas de trabalho. Gere relatórios por períodos e saiba quem está online em tempo real.",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-track.png"),
  },
  conecta_transfer: {
    title: "Conecta Transfer",
    key: "conecta_transfer",
    description: "",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-transfer.png"),
  },
  conecta_control: {
    title: "Conecta Control",
    key: "conecta_control",
    description:
      "Defina quem terá permissão para acessar as ferramentas do Google Workspace (G Suite), de qualquer lugar a qualquer hora. E quem só poderá acessar da empresa no horário de trabalho.",
    short_description:
      "Controle os horários e locais de utilização do Google Workspace (GSuite)",
    image: require("@/assets/appslogos/icons/icon-control.png"),
  },
  conecta_suite: {
    title: "Conecta Suite",
    key: "conecta_suite",
    description: "Essa opção faz parte do painel administrativo da empresa!",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  reseller: {
    title: "Conecta Partners",
    key: "reseller",
    description:
      "Gerencie seus clientes e revise o faturamento através do painel administrativo",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  admin: {
    title: "Permissões de Usuários",
    key: "admin",
    description: "Gerencie administradores e dê acessos para gestores",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  company_profile: {
    title: "Perfil da Empresa",
    key: "profile",
    description: "Gerencie os dados de perfil da sua empresa!",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  billing: {
    title: "Faturamento",
    key: "billing",
    description: "Acesse suas assinaturas, serviços e histórico de cobrança!",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  users: {
    title: "Usuários",
    key: "users",
    description:
      "Configure: mensagem de férias, delegação de acesso, enviar e-mail como e assinatura de e-mail!",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  inventory: {
    title: "Inventário digital",
    key: "inventory",
    description: "Gerencie o inventário digital da sua empresa!",
    short_description: "Gerencie o inventário digital da sua empresa!",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
  reports: {
    title: "Relatórios",
    key: "reports",
    description: "Acompanhe relatórios de atividades dos usuários!",
    short_description: "",
    image: require("@/assets/appslogos/icons/icon-suite.png"),
  },
};

export const productsFeatures = {
  conecta_suite: [
    "Criação, edição e ativação mensagem de férias",
    "Delegação acesso a caixa de e-mail – Gmail",
    "Configuração do enviar e-mail como",
    "Criação e edição das assinaturas de e-mail",
    "Registro do último login realizado",
  ],
  conecta_sign: [
    "Criação e edição de assinaturas de e-mail com editor intuitivo",
    "Aplicação das assinaturas de e-mail em massa",
    "Criação, edição e ativação de mensagem de férias em massa",
    "Modelos prontos de assinaturas de e-mail",
  ],
  conecta_control: [
    "Controle do login por horário e local",
    "Restrição do acesso aos dados do Drive, Gmail e Docs por horário e Local",
    "Logout automático do Google Workspace ao final do expediente de trabalho",
    "Logout avançado do Google Workspace nos dispositivos móveis",
    "Logout avançado do Gmail em outros leitores de e-mail – Outlook, Apple Mail",
    "Bloqueio do acesso ao Google Workspace nos feriados",
    "Bloqueio do acesso ao Google Workspace no período de férias ou afastamento",
    "Grupos personalizados de regras de acesso",
  ],
  conecta_control_integrations: [
    "Ponto Mais Integration - Bloqueio do Login até o registro do ponto",
    "AD Integration - Gestão de senhas unificada pelo Active Directory (AD da Windows)",
  ],
};

export const productsFeaturesResume = {
  conecta_suite:
    "Gerencie as mensagens de férias, assinaturas de e-mail, configurações de enviar e-mail como e delegações de acesso ao Gmail através de um painel intuitivo",
  conecta_sign:
    "Gerencie as assinaturas de e-mail e mensagens de férias dos seus colaboradores em massa de forma simples, fácil e ágil.",
  conecta_control:
    "Defina turnos com grupos de pessoas que tem permissão para acessar as ferramentas do Google Workspace onde e quando quiser e também quem só pode acessar no local de trabalho.",
  conecta_control_integrations:
    "Amplie a restrição de acesso com as integrações do Control",
};

export const productsByRankPlan = {
  10: ["conecta_suite"],
  20: ["conecta_suite", "conecta_sign"],
  30: ["conecta_suite", "conecta_sign", "conecta_control"],
  40: [
    "conecta_suite",
    "conecta_sign",
    "conecta_control",
    "conecta_control_integrations",
  ],
};

export const productsBySlugPlan = {
  basic: ["conecta_suite"],
  standard: ["conecta_suite", "conecta_sign"],
  security: ["conecta_suite", "conecta_sign", "conecta_control"],
  enterprise: [
    "conecta_suite",
    "conecta_sign",
    "conecta_control",
    "conecta_control_integrations",
  ],
};

export const planFeatureDescription = {
  [BASIC_SKU]: [
    "selfCheckout.plansDescriptions.basic.availability",
    "selfCheckout.plansDescriptions.basic.individualSignatures",
    "selfCheckout.plansDescriptions.basic.absenceManagement",
    "selfCheckout.plansDescriptions.basic.groupManagement",
    "selfCheckout.plansDescriptions.basic.externalEmail",
    "selfCheckout.plansDescriptions.basic.delegatedManagement",
    "selfCheckout.plansDescriptions.basic.adminReport",
    "selfCheckout.plansDescriptions.basic.userSetup",
    "selfCheckout.plansDescriptions.basic.accessSuspension",
  ],
  [STANDARD_SKU]: [
    "selfCheckout.plansDescriptions.standard.availability",
    "selfCheckout.plansDescriptions.standard.basicBenefits",
    "selfCheckout.plansDescriptions.standard.bulkSignatures",
    "selfCheckout.plansDescriptions.standard.createSignatureTemplates",
    "selfCheckout.plansDescriptions.standard.scheduledUpdates",
    "selfCheckout.plansDescriptions.standard.absenceSignatures",
    "selfCheckout.plansDescriptions.standard.signatureTemplates",
    "selfCheckout.plansDescriptions.standard.connectedApps",
    "selfCheckout.plansDescriptions.standard.delegationExpiration",
  ],
  [SECURITY_SKU]: [
    "selfCheckout.plansDescriptions.security.availability",
    "selfCheckout.plansDescriptions.security.basicBenefits",
    "selfCheckout.plansDescriptions.security.standardBenefits",
    "selfCheckout.plansDescriptions.security.autoReapply",
    "selfCheckout.plansDescriptions.security.accessControl",
    "selfCheckout.plansDescriptions.security.customShifts",
    "selfCheckout.plansDescriptions.security.deviceDisconnect",
    "selfCheckout.plansDescriptions.security.licenseDeactivation",
    "selfCheckout.plansDescriptions.security.loginReport",
    "selfCheckout.plansDescriptions.security.fileSharingReport",
    "selfCheckout.plansDescriptions.security.customLogin",
    "selfCheckout.plansDescriptions.security.migrationBackup",
  ],
  [ENTERPRISE_SKU]: [
    "selfCheckout.plansDescriptions.enterprise.availability",
    "selfCheckout.plansDescriptions.enterprise.basicBenefits",
    "selfCheckout.plansDescriptions.enterprise.standardBenefits",
    "selfCheckout.plansDescriptions.enterprise.securityBenefits",
    "selfCheckout.plansDescriptions.enterprise.seniorIntegration",
    "selfCheckout.plansDescriptions.enterprise.vrGenteIntegration",
    "selfCheckout.plansDescriptions.enterprise.activeDirectory",
    "selfCheckout.plansDescriptions.enterprise.otherIntegrations",
  ],
};

export const planFeaturesDifferences = {
  [BASIC_SKU]: {
    [STANDARD_SKU]: [],
    [SECURITY_SKU]: [],
    [ENTERPRISE_SKU]: [],
  },
  [STANDARD_SKU]: {
    [BASIC_SKU]: planFeatureDescription[STANDARD_SKU].slice(1).map(
      (textKey, index) => ({
        textKey,
        difference: index < 1 ? "plus" : "minus",
      })
    ),
    [SECURITY_SKU]: [],
    [ENTERPRISE_SKU]: [],
  },
  [SECURITY_SKU]: {
    [BASIC_SKU]: planFeatureDescription[STANDARD_SKU].slice(1)
      .concat(planFeatureDescription[SECURITY_SKU].slice(3))
      .map((textKey, index) => ({
        textKey,
        difference: index < 1 ? "plus" : "minus",
      })),
    [STANDARD_SKU]: planFeatureDescription[SECURITY_SKU].slice(1).map(
      (textKey, index) => ({
        textKey,
        difference: index < 2 ? "plus" : "minus",
      })
    ),
    [ENTERPRISE_SKU]: [],
  },
  [ENTERPRISE_SKU]: {
    [BASIC_SKU]: planFeatureDescription[STANDARD_SKU].slice(1)
      .concat(planFeatureDescription[SECURITY_SKU].slice(3))
      .concat(planFeatureDescription[ENTERPRISE_SKU].slice(4))
      .map((textKey, index) => ({
        textKey,
        difference: index < 1 ? "plus" : "minus",
      })),
    [STANDARD_SKU]: planFeatureDescription[SECURITY_SKU].slice(1)
      .concat(planFeatureDescription[ENTERPRISE_SKU].slice(4))
      .map((textKey, index) => ({
        textKey,
        difference: index < 2 ? "plus" : "minus",
      })),
    [SECURITY_SKU]: planFeatureDescription[ENTERPRISE_SKU].slice(1).map(
      (textKey, index) => ({
        textKey,
        difference: index < 3 ? "plus" : "minus",
      })
    ),
  },
};
planFeaturesDifferences["PRO-PLA-122021"] =
  planFeaturesDifferences[ENTERPRISE_SKU];
