<template>
  <v-container
    :class="`${
      $vuetify.breakpoint.mdAndUp ? 'px-5' : 'px-0'
    } fill-height justify-center`"
    style="width: 100%"
  >
    <PurchaseLoading
      :show="showPurchaseLoading"
      :creating-plan="loadingPurchase"
      :created-plan="createdPlan"
      :is-credit-card-payment="isCreditCardPayment"
      :error="setErrorState"
      @reset-created-plan="createdPlan = false"
      @reset-error-state="
        (setErrorState = false), (showPurchaseLoading = false)
      "
      @hide="showPurchaseLoading = false"
    />

    <Alert
      v-if="isSimulation"
      width="100%"
      color="warning"
      class="mx-4 mt-6 py-4"
      dense
    >
      <span v-html="$t('selfCheckout.simulationAlert')"></span>
    </Alert>

    <v-stepper
      v-if="!isSimulation && !showHelpWithPayment"
      v-model="steps"
      class="mb-8 elevation-0 pa-0 ma-0"
      style="width: 100%"
    >
      <v-stepper-header class="d-none">
        <v-stepper-step :complete="steps > 1" step="1" />
        <v-stepper-step step="2" />
      </v-stepper-header>

      <v-stepper-items class="px-0 mx-0">
        <v-stepper-content step="1" class="px-0 mx-0">
          <v-row
            class="mx-5 mt-2 mb-6 center"
            justify="center"
            v-if="hasEndedTrial"
          >
            <v-col class="text-center" cols="12">
              <h2
                class="mb-2 text-h4 primary--text text--lighten-2 font-weight-bold"
              >
                {{ $t("selfCheckout.trialEnded") }}
              </h2>
              <div class="mt-4">
                {{ $t("selfCheckout.trialEndedText") }}
              </div>
            </v-col>
          </v-row>
          <v-card flat tile>
            <PurchasePlansSelector @select-plan="getSelectedPlan" />
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card flat tile>
            <v-row :class="`ma-0 pa-0`">
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? 7 : 12"
                :class="`${$vuetify.breakpoint.xs ? 'mx-0 px-0' : ''}`"
              >
                <v-subheader class="font-weight-bold">
                  {{ $t("selfCheckout.cart") }}
                </v-subheader>

                <PlanInCartCard @clean:selfcheckout-cart="clearCart" />

                <v-subheader class="font-weight-bold mt-4">
                  {{ $t("companyProfile.companyInfo") }}
                </v-subheader>

                <CompanyInformation />
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? 5 : 12"
                :class="`${$vuetify.breakpoint.xs ? 'mx-0 px-0' : ''}`"
              >
                <SelectPaymentType v-if="!isBasicSelected" />

                <!-- PURCHASE INFO AND DATES -->
                <v-subheader class="font-weight-bold">
                  {{ $t("selfCheckout.orderDetails") }}
                </v-subheader>

                <v-card outlined>
                  <v-list class="pl-0">
                    <v-list-item
                      v-for="(orderConfig, index) in orderConfigInformation"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-subtitle
                          class="caption font-weight-medium break-word"
                        >
                          {{ orderConfig.title }}
                        </v-list-item-subtitle>
                        <v-list-item-title>
                          <div
                            v-if="
                              orderConfig.key === 'init_billing' &&
                              remainingTrialDays &&
                              !nextBillingIsToday &&
                              !isBasicSelected
                            "
                            class="text-decoration-line-through grey--text"
                          >
                            {{ orderNextBillingWithoutTrialRemainingDays }}

                            <v-chip
                              color="success"
                              class="font-weight-medium mb-1 ml-1"
                              small
                            >
                              <v-icon v-text="'mdi-check-circle'" small left />
                              {{ remainingTrialDays }}
                            </v-chip>
                          </div>
                          {{
                            isBasicSelected
                              ? $t("selfCheckout.notApplicable")
                              : orderConfig.subtitle
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>

                <!-- TOTAL & PAYMENT -->
                <v-card
                  v-if="$vuetify.breakpoint.smAndUp"
                  :color="$vuetify.theme.dark ? '' : 'grey lighten-4'"
                  :class="`mt-4 pa-6 ${
                    $vuetify.breakpoint.smAndDown ? 'mb-5' : ''
                  }`"
                  flat
                >
                  <v-card-title class="pa-0 mb-2">{{
                    $t("common.total")
                  }}</v-card-title>

                  <TotalPlanPrice />

                  <Alert
                    v-if="totalPriceDoNotAchieveMinValue && !isBasicSelected"
                    color="error"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ minValueMessage }}
                  </Alert>

                  <Alert
                    v-if="isAnnualDowngrade"
                    color="warning"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ $t("selfCheckout.annualDowngradeBlock") }}
                  </Alert>

                  <Alert
                    v-if="isAnnualToMonthly"
                    color="warning"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ $t("selfCheckout.subscriptionTypeChangeBlock") }}
                  </Alert>

                  <div v-if="$vuetify.breakpoint.mdAndUp">
                    <v-btn
                      v-if="selfChekoutTotalPrice || isBasicSelected"
                      x-large
                      color="accent"
                      elevation="0"
                      block
                      :loading="loadingPurchase"
                      class="px-6 text-none text-h6 font-weight-medium rounded-lg mt-4"
                      :disabled="
                        doNotHaveCNPJ ||
                        !hasFinancialEmail ||
                        isAnnualDowngrade ||
                        isAnnualToMonthly ||
                        (totalPriceDoNotAchieveMinValue && !isBasicSelected)
                      "
                      @click="verifyContractedProductsOrSeePlan"
                    >
                      {{
                        alreadyOwnedPlan
                          ? $t("common.seePlan")
                          : isCreditCardPayment
                          ? $t("selfCheckout.goToPayment")
                          : $t("selfCheckout.finishBuy")
                      }}
                      <v-icon
                        v-text="'mdi-arrow-right'"
                        class="arrow-left-transition"
                        right
                      />
                    </v-btn>

                    <ErrorReportButtonVue v-else />

                    <v-btn
                      v-if="selfChekoutTotalPrice || isBasicSelected"
                      large
                      text
                      color="grey darken-1"
                      elevation="0"
                      block
                      :loading="loadingPurchase"
                      class="px-6 text-none text-body-1 font-weight-medium rounded-lg mt-4"
                      :disabled="loadingPurchase"
                      @click="clearCart"
                    >
                      {{ $t("selfCheckout.clearCart") }}
                    </v-btn>
                  </div>
                </v-card>

                <div
                  v-if="
                    !isAnnualDowngrade ||
                    !isAnnualToMonthly ||
                    !(totalPriceDoNotAchieveMinValue && !isBasicSelected)
                  "
                  style="height: 60px"
                  class="py-4"
                />
              </v-col>
            </v-row>

            <v-footer
              v-if="$vuetify.breakpoint.smAndDown"
              :fixed="$vuetify.breakpoint.sm"
              class="py-4"
            >
              <v-row class="ma-0 pa-0">
                <v-col cols="12" xs="12" v-if="$vuetify.breakpoint.xs">
                  <v-subheader class="px-0">{{
                    $t("common.total")
                  }}</v-subheader>

                  <TotalPlanPrice />

                  <Alert
                    v-if="totalPriceDoNotAchieveMinValue && !isBasicSelected"
                    color="error"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ minValueMessage }}
                  </Alert>
                  <Alert
                    v-if="isAnnualDowngrade"
                    color="warning"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ $t("selfCheckout.annualDowngradeBlock") }}
                  </Alert>
                  <Alert
                    v-if="isAnnualToMonthly"
                    color="warning"
                    dark
                    dense
                    class="mt-3"
                  >
                    {{ $t("selfCheckout.annualDowngradeBlock") }}
                  </Alert>
                </v-col>
                <v-col
                  v-if="$vuetify.breakpoint.smAndUp"
                  cols="12"
                  xs="12"
                  sm="6"
                  :order="$vuetify.breakpoint.xs ? 2 : 1"
                  class="py-1"
                >
                  <v-btn
                    v-if="selfChekoutTotalPrice || isBasicSelected"
                    color="grey darken-2"
                    :class="`text-none ${
                      $vuetify.breakpoint.smAndDown
                        ? 'text-body-2 my-auto'
                        : 'text-body-1'
                    } font-weight-medium`"
                    :disabled="loadingPurchase"
                    :block="$vuetify.breakpoint.xs"
                    text
                    x-large
                    @click="goBackToPlans"
                  >
                    {{ $t("selfCheckout.clearCart") }}
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  sm="6"
                  :order="$vuetify.breakpoint.xs ? 1 : 2"
                  class="py-1 d-flex justify-end"
                >
                  <v-btn
                    v-if="selfChekoutTotalPrice || isBasicSelected"
                    x-large
                    color="accent"
                    elevation="0"
                    :block="$vuetify.breakpoint.xs"
                    :loading="loadingPurchase"
                    :class="`px-6 text-none x text-body-1 font-weight-medium rounded-pill`"
                    :disabled="
                      doNotHaveCNPJ ||
                      (totalPriceDoNotAchieveMinValue && !isBasicSelected)
                    "
                    @click="verifyContractedProductsOrSeePlan"
                  >
                    {{
                      alreadyOwnedPlan
                        ? $t("common.seePlan")
                        : isCreditCardPayment
                        ? $t("selfCheckout.goToPayment")
                        : $t("selfCheckout.finishBuy")
                    }}
                    <v-icon
                      v-text="'mdi-arrow-right'"
                      class="arrow-left-transition"
                      right
                    />
                  </v-btn>
                  <ErrorReportButtonVue v-else />
                </v-col>
              </v-row>
            </v-footer>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- Confirm new plan window -->
    <ConfirmNewPlan
      v-if="showConfirmNewPlan && impactedCompanyPlans && isDowngrade"
      @close="
        () => {
          closeHiringModal();
          clearCart();
        }
      "
      :actualCompanyPlan="impactedCompanyPlans?.[0]"
      :newCompanyPlan="temporaryCompanyPlan"
      @hire-new-plan="hireNewPlan(true)"
    />

    <HeaderDialog
      v-if="impactedCompanyPlans && !isDowngrade"
      id="confirm_new_plan"
      :subtitle="$t('selfCheckout.confirmNewPlan')"
      :title="selfCheckoutPlanName"
      width="800"
      :show="showConfirmNewPlan"
      :loading="loadingPurchase"
      @action="hireNewPlan(true)"
      @close="closeHiringModal"
      :action-text="actionText"
      :close-text="$t('action.cancel')"
      :toolbarMaxHeight="80"
    >
      <template v-slot:body>
        <v-container>
          <p class="ma-0">
            {{ $t("selfCheckout.overwriteCurrentPlan") }}
          </p>

          <v-card-text class="px-center pt-0">
            <v-row no-gutters dense class="align-center ma-0 pa-0">
              <v-col :cols="$vuetify.breakpoint.xs ? 12 : 5" class="pa-0">
                <ComparativePlan />
              </v-col>

              <v-col
                v-if="!$vuetify.breakpoint.xs"
                :cols="2"
                class="pa-0 d-flex justify-center"
              >
                <v-icon
                  class="mx-auto"
                  v-text="
                    $vuetify.breakpoint.xs
                      ? 'mdi-arrow-down-thick'
                      : 'mdi-arrow-right-thick'
                  "
                  :size="$vuetify.breakpoint.xs ? 35 : 65"
                  right
                />
              </v-col>

              <v-col :cols="$vuetify.breakpoint.xs ? 12 : 5" class="py-0">
                <ComparativePlan
                  v-if="hasTemporaryCompanyPlan"
                  :new="temporaryCompanyPlan"
                  :nextBilling="
                    hasBasicInTrial
                      ? formatDateForDatabase(today)
                      : orderNextBilling
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="px-center pt-4 pb-0">
            <Alert v-if="isDowngrade" color="warning" class="mb-0">
              {{ $t("selfCheckout.overwriteCurrentPlanAlert") }}
            </Alert>
          </v-card-text>
        </v-container>
      </template>
    </HeaderDialog>

    <HelpWithPayment
      v-if="showHelpWithPayment"
      :title="paymentTitle"
      :featuredList="paymentHelpFeatureList"
      :is-credit-card-payment="isCreditCardPayment"
      @backToPlans="goBackToPlans"
    />
  </v-container>
</template>
<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import moment from "moment";
import { logBtnEvent } from "@/helpers/services/registerAnalytics";
import { emails } from "@/helpers/variables/contactConecta";
import {
  moveScreenToUp,
  greaterOrEqualToday,
  addDays,
  today,
  formatDate,
  ticketExpiration,
  formatDateForDatabase,
  getRemainingTrialDays,
  checkIfDatesAreEqual,
} from "@/helpers/services/utils";
import { STATUS } from "@/helpers/variables/backendConstants";
import { updateControlDomainStatus } from "@/helpers/services/conectaControl";
import { isAnnual, isFlex, isActivePlan } from "@/helpers/services/companyPlan";
import { mapGetters, mapActions, mapMutations } from "vuex";

import CompanyInformation from "@/components/purchase/selfcheckout/CompanyInformation";
import ComparativePlan from "@/components/purchase/ComparativePlan.vue";
import ErrorReportButtonVue from "@/components/general/ErrorReportButton.vue";
import HelpWithPayment from "@/components/purchase/selfcheckout/HelpWithPayment";
import PlanInCartCard from "@/components/purchase/selfcheckout/PlanInCartCard";
import PurchasePlansSelector from "@/components/purchase/PurchasePlansSelector";
import SelectPaymentType from "@/components/purchase/selfcheckout/SelectPaymentType.vue";
import TotalPlanPrice from "@/components/purchase/selfcheckout/TotalPlanPrice.vue";
import PurchaseLoading from "@/components/purchase/selfcheckout/PurchaseLoading";
import ConfirmNewPlan from "@/components/purchase/selfcheckout/ConfirmNewPlan";
import { getDatabase, ref, child, get } from "firebase/database";

export default {
  name: "UpgradePlan",

  components: {
    CompanyInformation,
    ComparativePlan,
    ErrorReportButtonVue,
    HelpWithPayment,
    PlanInCartCard,
    PurchasePlansSelector,
    SelectPaymentType,
    TotalPlanPrice,
    PurchaseLoading,
    ConfirmNewPlan,
  },

  data() {
    return {
      showConfirmNewPlan: false,
      showHelpWithPayment: false,
      steps: 1,
      loadingPurchase: false,
      today,
      selectedPlan: null,
      actionText: this.$t("selfCheckout.confirmPlanPurchaseText"),
      createdPlan: false,
      showPurchaseLoading: false,
      setErrorState: false,
    };
  },

  computed: {
    ...mapGetters([
      "selfCheckoutService",
      "hasContractedPlans",
      "company",
      "currentUser",
      "token",
      "isSimulation",
      "doNotHaveCNPJ",
      "plans",
      "currentSelectedPlan",
      "impactedCompanyPlans",
      "clientHasThisPlan",
      "usersNumber",
      "companyImpactedPlansInTrial",
      "selfCheckoutPlan",
      "selfCheckoutMaxLicenses",
      "selfCheckoutSubscriptionType",
      "selfCheckoutDiscount",
      "selfChekoutTotalPrice",
      "selfCheckoutPlanName",
      "temporaryCompanyPlan",
      "selfCheckoutPlanKey",
      "selfCheckoutPlanProducts",
      "companyImpactedPlans",
      "hasBasicInTrial",
      "companyHasThisPlanActiveOrTrial",
      "isCreditCardPayment",
      "hasEndedTrial",
      "isIntrestedInService",
      "maxValueAllowedForCardPayment",
    ]),

    isBasicSelected() {
      return this.selectedPlan
        ? this.selectedPlan?.is_basic
          ? true
          : this.selectedPlan.plan?.is_basic
        : false;
    },

    hasFinancialEmail() {
      return !!this.company.financial_email;
    },

    totalPriceDoNotAchieveMinValue() {
      return this.selfChekoutTotalPrice < 40;
    },

    isFlex() {
      return isFlex(this.selfCheckoutSubscriptionType);
    },

    isAnnual() {
      return isAnnual(this.selfCheckoutSubscriptionType);
    },

    isDowngrade() {
      const impactedPlans = this.companyImpactedPlans.flatMap((item) =>
        item.plan.products.flatMap((product) => product.name)
      );
      const newPlan = this.selfCheckoutPlanProducts;
      return impactedPlans.find((item) => !newPlan.includes(item)) || false;
    },

    clientHasAnnualPlan() {
      return (
        this.companyImpactedPlans.some((companyPlan) =>
          isAnnual(companyPlan.subscription_type)
        ) || isAnnual(this.clientHasThisPlan?.subscription_type)
      );
    },

    isAnnualDowngrade() {
      return (
        this.isDowngrade &&
        this.clientHasAnnualPlan &&
        !(this.isFlex && !this.isBasicSelected)
      );
    },

    isAnnualToMonthly() {
      return this.isFlex && !this.isBasicSelected && this.clientHasAnnualPlan;
    },

    alreadyOwnedPlan() {
      return (
        isActivePlan(this.clientHasThisPlan?.status) &&
        this.clientHasThisPlan.subscription_type ==
          this.selfCheckoutSubscriptionType
      );
    },

    minValueMessage() {
      let message = this.$t("hirePlan.paymentMinimumValue");

      if (this.isFlex) {
        message += this.$t("hirePlan.paymentMinimumValueTextforFlex");
      } else if (
        !this.isBasicSelected &&
        this.isAnnual &&
        this.selfChekoutTotalPrice < 40
      ) {
        message += this.$t("hirePlan.paymentMinimumValueTextforAnnual");
      } else {
        message = "";
      }

      return message;
    },

    paymentHelpFeatureList() {
      if (this.isCreditCardPayment) {
        return [
          {
            title: this.$t("selfCheckout.helpCardFeatureListTitle1"),
            subtitle: this.$t("selfCheckout.helpCardFeatureListSubtitle1"),
          },
          {
            title: this.$t("selfCheckout.helpCardFeatureListTitle2"),
            subtitle: this.$t("selfCheckout.helpCardFeatureListSubtitle1"),
          },
          {
            title: this.$t("selfCheckout.helpCardFeatureListTitle3"),
            subtitle: this.$t("selfCheckout.helpCardFeatureListSubtitle3"),
          },
        ];
      } else {
        return [
          {
            title: this.$t("selfCheckout.helpBankSlipFeatureListTitle1"),
            subtitle: this.$t("selfCheckout.helpBankSlipFeatureListSubtitle1"),
          },
          {
            title: this.$t("selfCheckout.helpBankSlipFeatureListTitle2"),
            subtitle: this.$t("selfCheckout.helpBankSlipFeatureListSubtitle2"),
          },
          {
            title: this.$t("selfCheckout.helpBankSlipFeatureListTitle3"),
            subtitle: this.$t("selfCheckout.helpBankSlipFeatureListSubtitle3"),
          },
        ];
      }
    },

    paymentTitle() {
      return this.isCreditCardPayment
        ? "selfCheckout.almostThere"
        : "selfCheckout.bankSlipPaymentTitle";
    },

    nextBillingIsToday() {
      return checkIfDatesAreEqual(this.orderNextBilling, today);
    },

    remainingTrialDays() {
      return getRemainingTrialDays(this.nearestEndTrial[0]);
    },

    ticketExpiration() {
      if (this.hasBasicInTrial) {
        return ticketExpiration(today);
      }
      return ticketExpiration(
        formatDate(this.orderNextBilling),
        this.selfCheckoutSubscriptionType
      );
    },

    orderConfigInformation() {
      let initBillingText = "";

      if (this.hasBasicInTrial) {
        initBillingText = formatDate(today);
      } else if (
        this.companyImpactedPlansInTrial.length > 0 &&
        !this.nextBillingIsToday
      ) {
        initBillingText = `${this.$t(
          "selfCheckout.getBasicAfterTrialEnded"
        )} ${formatDate(this.orderNextBilling)})`;
      } else {
        initBillingText = formatDate(this.orderNextBilling);
      }

      return [
        {
          title: this.$t("billing.initBilling"),
          key: "init_billing",
          subtitle: initBillingText,
          show: true,
        },
        {
          title: this.$t("selfCheckout.bankSlipDueDate"),
          key: "ticket_expiration",
          subtitle: this.ticketExpiration,
          show: !this.isCreditCardPayment,
        },
      ].filter((item) => item.show);
    },

    orderNextBilling() {
      if (this.nearestEndTrial.length) {
        return greaterOrEqualToday(this.nearestEndTrial[0])
          ? formatDateForDatabase(addDays(this.nearestEndTrial[0], 1))
          : formatDateForDatabase(today);
      }

      return formatDateForDatabase(today);
    },

    orderNextBillingWithoutTrialRemainingDays() {
      return formatDate(today);
    },

    nearestEndTrial() {
      return this.impactedCompanyPlansInTrial
        .map((companyPlan) => companyPlan.end_trial)
        .sort((a, b) => new Date(b) - new Date(a));
    },

    impactedCompanyPlansInTrial() {
      return this.companyImpactedPlansInTrial.filter(
        (companyPlan) => companyPlan.status === STATUS.TRIAL
      );
    },

    hasTemporaryCompanyPlan() {
      return Object.keys(this.temporaryCompanyPlan).length > 0;
    },
  },

  watch: {
    plans() {
      if (this.$route.query.plan) {
        this.selectPlan(this.$route.query.plan);
      }
    },
    async "$route.fullPath"() {
      await this.startLoadClient();
    },

    selfCheckoutSubscriptionType() {
      if (this.isFlex) {
        this.setSelfCheckoutMaxLicenses(this.usersNumber);
      }
    },

    steps() {
      if (this.steps === 1) {
        this.setSelfCheckoutPlanInCart(null);
        this.setSelfCheckoutServiceInCart();
        this.setIntrestInService(false);
      }
    },
  },

  methods: {
    ...mapActions(["getPlans", "getContractedPlans"]),

    ...mapMutations([
      "setSelectedPlan",
      "setClientView",
      "setSnackBar",
      "setSidebarMini",
      "setSelfCheckoutPlanInCart",
      "setSelfCheckoutServiceInCart",
      "setIntrestInService",
      "setSelfCheckoutMaxLicenses",
      "setSelfCheckoutPaymentMethod",
      "setCompany",
      "setMaxValueAllowedForCardPayment",
    ]),

    formatDateForDatabase,

    getMaximunPriceForCreditCard() {
      if (this.maxValueAllowedForCardPayment) {
        return;
      }

      const dbRef = ref(getDatabase());
      get(child(dbRef, `suite_config/maxValueAllowedForCardPayment`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            this.setMaxValueAllowedForCardPayment(snapshot.val());
          } else {
            this.setDefaultMaxValueCardPayment();
          }
        })
        .catch((error) => {
          console.error(error);
          this.setDefaultMaxValueCardPayment();
        });
    },

    setDefaultMaxValueCardPayment() {
      const defaulValue = 500;
      this.setMaxValueAllowedForCardPayment(defaulValue);
    },

    sendSelfCheckoutActionToFirebase(where, description = "") {
      const analytics = getAnalytics();

      logEvent(analytics, "self-checkout", {
        click: where,
        main_domain: this.mainDomain,
        email: this.currentUser.email,
        description: description,
      });
    },

    clearCart() {
      const analytics = getAnalytics();
      const { main_domain } = this.company;
      const clicked_where = "clear cart";
      const user = this.currentUser.email;

      logEvent(analytics, "billing_upgrade", {
        main_domain,
        user,
        clicked_where,
        date: moment().format("DD/MM/YYYY"),
      });
      this.abandonedCart();
    },

    closeHiringModal() {
      this.sendSelfCheckoutActionToFirebase(
        "Cancelar upgrade",
        "Cancelamento da modal de upgrade (quando é troca de plano) dentro do self-checkout"
      );

      this.showConfirmNewPlan = false;
    },

    getSelectedPlan(plan_details) {
      const { key } = plan_details;

      const planDetails = this.plans.find((plan) => plan.key === key);

      if (planDetails) {
        this.setSelfCheckoutPlanInCart(planDetails);
        this.setSelfCheckoutServiceInCart(planDetails);
      }

      this.selectPlan(key);
    },

    abandonedCart() {
      const analytics = getAnalytics();

      if (this.selfCheckoutPlan) {
        const { price, name } = this.selfCheckoutPlan;

        logEvent(analytics, "choose_plan_periodicity", {
          action: "select_plan_periodicity_in_step_two",
          plan_name: name,
          plan_price: price,
          discount: this.selfCheckoutDiscount,
          total_price: this.selfChekoutTotalPrice,
          subscription_type: this.selfCheckoutSubscriptionType,
          status: status,
          licenses: this.usersNumber,
          max_licenses: this.selfCheckoutMaxLicenses,
          hiring_step: this.steps,
          is_new_plan: !!this.companyHasThisPlanActiveOrTrial,
          main_domain: this.company.main_domain,
        });
      }

      this.sendSelfCheckoutActionToFirebase(
        "Abandonar carrinho",
        "Botão 'Abandonar carrinho' dentro da modal de desistência da página de self-checkout"
      );

      this.goBackToPlans(false);
    },

    goBackToPlans(show_event = true) {
      if (show_event) {
        this.sendSelfCheckoutActionToFirebase(
          "Voltar para listagem de planos",
          "Redirecionamento após desistência/agendamento para o passo 1 da página de self-checkout"
        );
      }

      this.steps = 1;
      this.showHelpWithPayment = false;
      this.setSelfCheckoutPaymentMethod("ticket");
    },

    verifyContractedProductsOrSeePlan() {
      if (this.alreadyOwnedPlan) {
        this.$router.push({
          name: "Company",
          query: { startingMenu: "plans_info" },
        });
      } else if (!this.hasContractedPlans) {
        this.hireNewPlan();
      } else if (
        this.isAnnual &&
        isAnnual(this.impactedCompanyPlans?.[0]?.subscription_type) &&
        this.isDowngrade
      ) {
        this.hireNewPlan();
      } else if (this.impactedCompanyPlans.length) {
        logBtnEvent({
          label: "Finalizar compra",
          action: "open_hire_modal",
        });

        this.sendSelfCheckoutActionToFirebase(
          "Finalizar compra",
          "Botão 'Finalizar compra' da página de self-checkout"
        );

        this.showConfirmNewPlan = true;
      } else {
        this.hireNewPlan();
      }
    },

    selectPlan(plan_key) {
      this.setSelectedPlan(plan_key); // Adiciona a chave do plano em questão

      if (this.clientHasThisPlan) {
        this.selectedPlan = this.clientHasThisPlan;
      } else {
        this.selectedPlan = this.currentSelectedPlan;
      }

      const { name, price } = this.selectedPlan;

      const analytics = getAnalytics();

      logEvent(analytics, "choose_plan", {
        action: "select_plan_in_step_one",
        plan_name: name,
        plan_price: price,
        is_new_plan: this.clientHasThisPlan ? false : true,
        main_domain: this.company.main_domain,
      });

      this.steps++;
    },

    async startLoadClient() {
      this.setClientView(true);

      if (this.$vuetify.breakpoint.mdAndUp) {
        this.setSidebarMini(true);
      }

      await this.getPlans();
      await this.getContractedPlans();

      moveScreenToUp();
      /* this.hasParamsOrNot(); */
    },

    hireNewPlan(has_impacted_plan = false) {
      // Keys sent: subscription_type, plan_key e max_licenses

      logBtnEvent("action", {
        label: has_impacted_plan ? this.actionText : "Confirmar Pedido",
        action: "hire_plan",
      });

      const url = `${process.env.VUE_APP_API_BASE_URL}/company/plans`;

      let payload = {
        plan_key: this.selfCheckoutPlanKey,
        subscription_type: this.isBasicSelected
          ? "FREE"
          : this.selfCheckoutSubscriptionType,
        has_intrest_in_service: this.isIntrestedInService,
        is_bank_slip: !this.isCreditCardPayment,
      };

      if (this.isAnnual && !this.isBasicSelected) {
        payload = {
          ...payload,
          max_licenses: parseInt(this.selfCheckoutMaxLicenses),
        };
      }

      if (
        this.isIntrestedInService &&
        !this.isBasicSelected &&
        this.selfCheckoutService
      ) {
        payload["service_key"] = this.selfCheckoutService.key;
      }

      this.loadingPurchase = true;

      if (!this.isBasicSelected) {
        this.showPurchaseLoading = true;
        this.showConfirmNewPlan = false;
      }

      this.$axios
        .post(url, payload, {
          headers: { Authorization: this.token },
        })
        .then(({ data }) => {
          this.setCompany(data);
          if (this.isBasicSelected) {
            this.setSnackBar({
              is_key: false,
              color: "success",
              message: `${this.$t("selfCheckout.hirePlanSnackbarPrefix")} ${
                this.selfCheckoutPlan.name
              } ${this.$t("selfCheckout.hirePlanSnackbarSuffix")} '${
                emails.experience
              }'`,
              message_key: "",
              timeout: "10000",
              showCloseButton: false,
              show: true,
            });
          } else {
            this.createdPlan = true;
            this.loadingPurchase = false;
          }
          updateControlDomainStatus(this.company.main_domain);
          if (!this.isBasicSelected && !this.setErrorState) {
            this.showHelpWithPayment = true;
          } else {
            this.$router.push({ name: "Home" });
          }
          this.selectedPlan = null;
        })
        .then(async () => {
          await this.getContractedPlans();
        })
        .catch((err) => {
          const { data } = err.response;

          this.setSnackBar({
            is_key: true,
            color: "error",
            message: "",
            message_key: data.code,
            timeout: data.code === "DOWNGRADE_BLOCK" ? "10000" : "",
            showCloseButton: true,
            show: true,
          });
          this.showPurchaseLoading = false;
        })
        .finally(() => {
          this.loadingPurchase = false;
          this.showConfirmNewPlan = false;
        });
    },
  },

  async beforeMount() {
    this.setSelfCheckoutMaxLicenses(this.usersNumber);

    await this.startLoadClient();
    this.getMaximunPriceForCreditCard();
  },

  beforeDestroy() {
    if (this.hasEndedTrial && !this.isSimulation) {
      this.$router.replace({ name: "BillingUpgradePlan" });
    }
  },
};
</script>
<style scoped>
.steps {
  max-width: 800px !important;
}
.purchased-cards {
  border-right: 1px solid #ccc !important;
}
.purchased-cards:last-child {
  border-right: 0px !important;
}
.list-plans {
  display: flex;
  justify-content: center;
}
@media (max-width: 763px) {
  .list-plans {
    display: block;
  }
  .purchased-cards {
    border: 1px solid #ccc !important;
    margin: 0 0 10px 0;
    width: 100% !important;
    border-radius: 8px !important;
  }
  .purchased-cards:last-child {
    border-right: 1px solid #ccc !important;
  }
}
</style>
