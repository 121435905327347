<template>
  <div>
    <div class="d-flex align-center mt-2 mb-4">
      <h1 class="font-weight-bold mr-4" style="font-size: 22px">
        {{ $t("companyProfile.billingHistory") }}
      </h1>
      <div max-width="300">
        <v-autocomplete
          :items="periods"
          :filter="customFilter"
          v-model="period"
          item-text="name"
          label="Período"
          single-line
          filled
          rounded
          hide-details
          dense
        ></v-autocomplete>
      </div>
    </div>
    <v-card class="transparent" elevation="0">
      <div class="mt-3" style="max-width: 400px" v-if="showCalendar">
        <Calendar
          type="month"
          :maximumDate="today"
          minimumDate="2021-10-01"
          @selectedDate="customPeriod = $event"
        />
      </div>
      <v-row class="mx-auto my-8">
        <TransactionTable :months="selectedDates" />
      </v-row>
    </v-card>
  </div>
</template>
<script>
import TransactionTable from "@/components/main/shared/TransactionTable";
import Calendar from "@/components/main/shared/Calendar";
import { formatPeriod } from "@/helpers/services/transactions";
import * as moment from "moment";
export default {
  components: { TransactionTable, Calendar },
  data() {
    return {
      period: "",
      customPeriod: [],
      id: null,
      periods: [
        { name: "Este mês", id: 0 },
        { name: "Mês passado", id: 1 },
        { name: "Últimos três meses", id: 2 },
        { name: "Este ano", id: 3 },
        //{ name: "Ano anterior", id: 4 },
        { name: "Personalizado", id: 5 },
      ],
      showCalendar: false,
      today: "",
      selectedDates: [],
    };
  },

  mounted() {
    this.today = moment().format("YYYY-MM-DD");
    this.period = "Últimos três meses";
  },

  watch: {
    period() {
      [this.selectedDates, this.showCalendar] = this.formatPeriod(this.period);
    },

    customPeriod() {
      this.formatCustomPeriod();
    },
  },

  methods: {
    formatPeriod,

    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },

    formatCustomPeriod() {
      this.id = [];
      this.selectedDates = [];

      let firstMonth = moment(this.customPeriod[0])
        .locale("pt-br")
        .format("YYYY-MM");
      let currentMonth = moment(this.customPeriod[1])
        .locale("pt-br")
        .format("YYYY-MM");
      let auxiliary = currentMonth;

      let i = 0;
      while (currentMonth >= firstMonth) {
        auxiliary = moment(currentMonth)
          .locale("pt-br")
          .format("MMMM [de] YYYY");
        auxiliary = auxiliary
          .substring(0, 1)
          .toUpperCase()
          .concat(auxiliary.substring(1));
        this.selectedDates.push({
          id: i,
          label: auxiliary,
          requestDate: currentMonth,
        });
        currentMonth = moment(currentMonth)
          .locale("pt-br")
          .add(-1, "months")
          .format("YYYY-MM");
        i++;
      }
    },
  },
};
</script>
