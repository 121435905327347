<template>
  <div>
    <v-dialog
      v-model="editMaxLicenses"
      max-width="400"
      @keydown.esc="editMaxLicenses = false"
      @click:outside="editMaxLicenses = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="isFlex"
          color="primary lighten-3"
          outlined
          text
          v-bind="attrs"
          v-on="on"
          x-large
          :block="$vuetify.breakpoint.mdAndDown"
          class="text-none text-body-1 font-weight-bold elevation-0 rounded-0 px-8 blink"
        >
          {{ `${$t("common.to")} ${updatedMaxLicenses}` }}
          {{
            updatedMaxLicenses === 1
              ? $t("common.license")
              : $t("common.licenses")
          }}

          <v-icon v-text="'mdi-pencil'" class="ml-3" size="24" right />
        </v-btn>
      </template>
      <v-card tile flat class="pb-5">
        <v-app-bar color="primary lighten-2" dark>
          <v-app-bar-title> {{ $t("common.licenseNumber") }} </v-app-bar-title>
          <v-spacer />
          <v-btn icon dark @click="editMaxLicenses = false">
            <v-icon v-text="'mdi-close'" />
          </v-btn>
        </v-app-bar>
        <v-card-text class="px-4 mt-4 break-word text-body-1">
          {{ $t("selfCheckout.licensesTipForAnnual") }}
        </v-card-text>

        <v-card-actions class="justify-center">
          <ButtonNumberIncrementer
            label="common.licenseNumber"
            label-class="d-flex justify-center"
            :value="updatedMaxLicenses"
            :min="usersNumber"
            @update="updatedMaxLicenses = $event"
            max-width="100%"
          />
        </v-card-actions>

        <v-card-actions class="mt-2">
          <v-btn
            color="primary"
            tile
            large
            block
            @click="editMaxLicenses = false"
          >
            {{ $t("action.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ButtonNumberIncrementer from "@/components/base/ButtonNumberIncrementer.vue";
import { SUBSCRIPTIONS } from "@/helpers/variables/backendConstants.js";
import { mapGetters } from "vuex";

export default {
  name: "LicensesDialogSelector",

  props: {
    subscriptionType: { type: String, required: true },
  },

  components: { ButtonNumberIncrementer },

  data() {
    return {
      SUBSCRIPTIONS,
      maxLicenses: 0,
      editMaxLicenses: false,
    };
  },

  computed: {
    ...mapGetters(["usersNumber", "selfCheckoutSubscriptionType"]),

    isFlex() {
      return this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.FLEX;
    },

    updatedMaxLicenses: {
      get() {
        return this.maxLicenses;
      },
      set(newVal) {
        this.maxLicenses = newVal;
      },
    },
  },

  watch: {
    updatedMaxLicenses() {
      this.$emit("update", this.updatedMaxLicenses);
    },

    selfCheckoutSubscriptionType() {
      if (this.selfCheckoutSubscriptionType === SUBSCRIPTIONS.FLEX) {
        this.reset();
      }
    },
  },

  methods: {
    reset() {
      this.maxLicenses = this.usersNumber;
    },
  },

  beforeMount() {
    this.reset();
  },
};
</script>
